  import React, { useContext, useEffect, useRef, useState } from 'react';
import client, {urlFor, urlForSVG, urlForTrack} from '../../lib/sanity';
import { Link, useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../assets/css/react-responsive-carousel.css";
import { StorageMediaContext } from '../../storageMedia/storageMediaContext';
import shortid from 'short-id';
import {useAudioPlayer} from '../../storageMedia/storageMediaHooks';

const Release = ({ }) => {
  const { state, dispatch } = useContext(StorageMediaContext);
  const { release, carousel } = state;
  console.log('Release release', release);
  const params = useParams();
  const releaseCode = params.id;

  const { playURL, playPause } = useAudioPlayer();
  const carouselRef = useRef();

  useEffect(() => {
    dispatch({ type: 'CAROUSEL_SET_REF', payload: { carousel: { ref: carouselRef }} });
  }, [dispatch, release]);

  const linkHandler = (e, track) => {
    e.preventDefault();
    const trackURL = urlForTrack(track);
    if (trackURL === state.audioPlayer.url) {
      playPause()
    } else {
      playURL(urlForTrack(track));
    }
  };

  const getLinkLabel = (track) => {
    const trackURL = urlForTrack(track);
    return (trackURL === state.audioPlayer.url && state.audioPlayer.playing) ? 'Stop' : 'Play';
  };

  useEffect(() => {
    async function fetchData() {
      // It's important to default the slug so that it doesn't return "undefined"
      const slug = releaseCode || "";
      const data = await client.fetch(`
      *[_type == "release" && slug.current == $slug][0]
    `, { slug });

      console.log('data', data);
      dispatch({ 'type': 'SET_RELEASE', 'payload': { release: data } });
      // dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'internalLink', 'payload': { id: shortid.generate(), 'text': `cd ${data.slug.current} ${data.artist} ${data.album}`, 'src': `releases/${data.slug.current}` } } });
    }
    fetchData();
    return () => {
      dispatch({ 'type': 'SET_RELEASE', 'payload': { release: null } });
    }
  }, [dispatch, releaseCode]);
  return (
    <div style={{ height: '100%'}}>
      {!release && <ContainerDiv className="loading-release" />}
      {release && (<ContainerDiv>
        <Carousel
          ref={carouselRef}
          showThumbs={false}
          showStatus={true}
          showIndicators={false}
          infiniteLoop={true}
          selectedItem={carousel.currentSlide}
          onChange={(idx) => dispatch({ type: 'CAROUSEL_SET_SLIDE', payload: { carousel: { currentSlide: idx }} })}
          useKeyboardArrows
        // className="presentation-mode"
        >
          <div className="my-slide secondary">
            <div className="wrap">
              <div className="cover-image">
                <img
                  alt="vase with red rose"
                  src={urlFor(release.mainImage)
                    .width(1200)
                    .url()}
                />
              </div>
            </div>
          </div>

          <div className="text-large my-slide secondary">
            <div className="wrap">
              <div className="title-large">
                <p>{release.artist}</p>
                <p>{release.album}</p>
              </div>
            </div>
          </div>

          <div className="text-small my-slide secondary">
            <div className="wrap">
              <div className="title-group">
                <p>STORAGE MEDIA {release.number}</p>
              </div>
              <div className="link-list-wrap">
                <ul className="link-list">
                  {release.tracks.map((track) => (
                    <StyledLi key={track._key} onClick={(e) => linkHandler(e, track)}>
                      <p>{track.name}</p>

                      <span>{getLinkLabel(track)}</span></StyledLi>
                  ))}
                </ul>
              </div>
              <div className="link-list-wrap">
                <ul className="link-list">
                  {release.externalLink.map((link) => (
                    <a key={link._key} target="_blank" href={link.link}>
                      <StyledLi>
                        <p>{link.service}</p>
                        <p>{link.action}</p>
                      </StyledLi>
                    </a>
                  ))}
                </ul>
              </div>

            </div>

          </div>

          <div className="text-small my-slide secondary">
            <div className="wrap">
              <div className="text-block">
                {release.description}
              </div>
              {/* <img width="1200" style={{'maxWidth': '1200px'}} alt="symbol of release" src={(release && release.symbol && urlForSVG(release))} /> */}
            </div>
          </div>


        </Carousel>

      </ContainerDiv>)}
    </div>
  );
};

const ContainerDiv = styled.div`
    background-color: white;
    color: black;
    height: 100vh;
    width: 100vw;
    top: 0;
    position: absolute;
    @media (max-width: 650px) {
      height: 100%;
      position: static;
    }
    `;

export default Release;

const StyledLi = styled.li`
  background-color: transparent;
  cursor: pointer;
  border: none;

  &:hover {
    color: gray;
  }

  &:active, &:focus {
    outline: none;
  }
`;
