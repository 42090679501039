import client from '../lib/sanity';
import shortid from 'short-id';

export const loadReleaseList = async (dispatch) => {
  const handleMouseLeave = () => () => {
    dispatch({'type': 'SET_TERMINAL_COLORS', 'payload': { terminalTextColor: 'white', terminalBgColor: 'black'} });
    dispatch({'type': 'SET_HOVERED_RELEASE', 'payload': { hoveredRelease: null }});
  };

  const handleMouseEnter = (release) => () => {
    dispatch({'type': 'SET_TERMINAL_COLORS', 'payload': { terminalTextColor: release.terminalText, terminalBgColor: release.terminalBackground}});
    dispatch({'type': 'SET_HOVERED_RELEASE', 'payload': { hoveredRelease: release }});
  };

  const releases = await client.fetch(`
      *[_type == "release"]
    `, {});
  // dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'emptyLine', 'payload': { id: shortid.generate() } } });
  // dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'internalLink', 'payload': { id: shortid.generate(), 'text': `cd releases`, 'src': `releases` }}});
  dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'emptyLine', 'payload': { id: shortid.generate() } } });
  releases.forEach((release) => {
    dispatch({
      'type': 'NEW_TERMINAL_LINE',
      'payload': {
        'type': 'internalLink',
        'payload': {
          'id': shortid.generate(),
          'text': `${release.slug.current} — ${release.artist}`,
          'src': `releases/${release.slug.current}`,
          'onMouseEnter': handleMouseEnter(release),
          'onMouseLeave': handleMouseLeave()
        }
      }
    });
  });
};
