import React, {useContext, useRef, useEffect} from 'react';
import styled from 'styled-components';
import ReleaseList from '../ReleaseList';
import { useHistory } from "react-router-dom";
import shortid from 'short-id';
import { StorageMediaContext } from '../../storageMedia/storageMediaContext'
import {loadReleaseList} from '../../storageMedia/storageMediaHelpers';

const renderTerminal = (terminalLines, scrollArea, history, dispatch) => {
  const handleInternalLinkClick = (line) => {
    dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'emptyLine', 'payload': { id: shortid.generate() } } });
    dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'internalLink', 'payload': { id: shortid.generate(), 'text': `cd ${line.text.replace(/^(cd )*(.*)/, '$2')}`, 'src': line.src }}});
    // dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'text', 'payload': { id: shortid.generate(), 'text': `Loading...` }}});
    if (line.src === '/releases') {
      loadReleaseList(dispatch)
    }

    setTimeout(() => {
      history.push(line.src);
    }, 700);
  };
  const handleInternalLinkMouseEnter = (line) => {
    if (line.onMouseEnter !== undefined) {
      line.onMouseEnter()
    }
  };
  const handleInternalLinkMouseLeave = (line) => {
    if (line.onMouseLeave !== undefined) {
      line.onMouseLeave()
    }
  };

  return (
    <div ref={scrollArea} style={{flex: 1,
      flexDirection: 'column',
      overflow: 'scroll',
      maxHeight: '100%',
      width: '100%',
      paddingBottom: '1rem'
    }}>
      {terminalLines.map((line) => {
        console.log('line', line);
        switch (line.type) {
          case 'emptyLine':
            return <p key={line.id}><br/></p>;
          case 'text':
            return <p key={line.id}>{line.text}</p>;
          case 'internalLink':
            if (line.text.startsWith('cd ')) {
              return <p key={line.id}>{line.text}</p>
            }
            return <p key={line.id}><span style={{ cursor: 'pointer'}} onClick={() => {
              handleInternalLinkClick(line)
            }} onMouseEnter={() => handleInternalLinkMouseEnter(line)}
                                          onMouseLeave={() => handleInternalLinkMouseLeave(line)}
                                          >{line.text}</span></p>;
          case 'externalLink':
            return <p key={line.id}><a href={line.src}>{line.text}</a></p>;
          default:
            console.log('renderTerminal: no type found');
            return null;
        }
      })}
    </div>
  )
};

const ReleaseTerminal = ({ release, carousel, terminalLines, dispatch, ReleaseTerminalRef }) => {
  let history = useHistory();
  console.log('ReleaseTerminal carousel.ref.current', carousel.ref.current);
  if (!release) return null;
  const links = ['cover', 'title', 'listen', 'information'];

  const handleReleaseTerminalLinkClick = (idx) => {
    if (carousel && carousel.ref && carousel.ref.current) {
      dispatch({ type: 'CAROUSEL_SET_SLIDE', payload: { carousel: { currentSlide: idx }} });
    }
  };

  const handleHomeClick = (ReleaseTerminalRef) => {
    setTimeout(() => history.push('/'), 500);
  };

  console.log('terminalLines', terminalLines);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
     <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={handleHomeClick}>
        <div style={{ lineHeight: '1'}}>storagemedia.net/releases/{release.number}</div>
        <div style={{ backgroundColor: 'black', width: '1rem', height: '1rem', borderRadius: '5000px', cursor: 'pointer' }} onClick={handleHomeClick} />
     </div>
      <ul style={{ listStyleType: 'none', padding: 0, margin: '1rem 0 0'}}>
        {links.map((link, index) => (
          <li style={{ margin: 0, padding: 0}} key={index}><span style={{ cursor: 'pointer' }} onClick={() => handleReleaseTerminalLinkClick(index)}>{link}</span></li>
       ))}
          {/*<li key='random' style={{marginTop: '0.5rem'}}><span style={{ cursor: 'pointer' }}>{terminalLines[terminalLines.length - 1].text}</span></li>*/}
      </ul>
   </div>
  )
};

const Terminal = ({ pathName, bgColor, textColor }) => {
  const { state, dispatch } = useContext(StorageMediaContext);
  const {terminalLines} = state;
  let history = useHistory();
  let ReleaseTerminalRef = useRef(null);
  const { release, carousel } = state;
  let modifiedPathName = pathName;
  const isReleaseURLRegex = /\/releases\/(\d+)/;

  if (isReleaseURLRegex.test(pathName)) {
    modifiedPathName = '/releases/:id'
  }

  useEffect(() => {
    if (modifiedPathName === '/releases/:id' && ReleaseTerminalRef.current) {
      window.gsap.from(ReleaseTerminalRef.current, {duration: 0.5, width: '100vw', height: '100vh'});
    }
    // return () => {
    //   console.log('unmount', modifiedPathName);
    //   console.log('unmount ref', ReleaseTerminalRef.current);
    //   if (modifiedPathName === '/' && ReleaseTerminalRef.current === null) {
    //     window.gsap.to(ReleaseTerminalRef.current, { duration: 2, width: '100vh', height: '100vh' });
    //   }
    // }
  }, [modifiedPathName]);

  const scrollArea = useRef();
  useEffect(() => {
    if (scrollArea && scrollArea.current) {
      scrollArea.current.scrollTop =
        scrollArea.current.scrollHeight - scrollArea.current.clientHeight
    }
  }, [terminalLines]);

  switch (modifiedPathName) {
    case '/':
      return <StyledDivFull id='terminalDiv' bgColor={bgColor} textColor={textColor}>
        <div style={{ width: '100%'}}>
          {/*<Homepage />*/}
          {renderTerminal(state.terminalLines, scrollArea, history, dispatch)}
        </div>
      </StyledDivFull>;
    case '/about':
      return <StyledDivFull id='terminalDiv' bgColor={bgColor} textColor={textColor}>
        <div style={{ width: '100%'}}>
          {renderTerminal(state.terminalLines, scrollArea, history, dispatch)}
        </div>
      </StyledDivFull>;
    case '/releases/:id':
      console.log('Terminal in release/:id');
      if (!release) return null;
      return <StyledDiv id='terminalDivRelease' ref={ReleaseTerminalRef} bgColor={release && release.terminalBackground} textColor={release && release.terminalText}>
        <div style={{ height: '100%'}}>
          <ReleaseTerminal release={release} carousel={carousel} dispatch={dispatch} terminalLines={terminalLines} ReleaseTerminalRef={ReleaseTerminalRef} />
        </div>
      </StyledDiv>;
    case '/releases':
      return <StyledDivFull id='terminalDiv' bgColor={bgColor} textColor={textColor}>
        <div style={{ width: '100%'}}>
          {renderTerminal(state.terminalLines, scrollArea, history, dispatch)}
        </div>
      </StyledDivFull>;
      case '/releases/000001':
        return <StyledDiv id='terminalDiv' bgColor={bgColor} textColor={textColor}>
          <ReleaseList />
        </StyledDiv>;
    default:
      return null;
  }
};

export default Terminal;

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin:0 ;
  background-color: ${props => props.bgColor ? props.bgColor : 'black'};
  color: ${props => props.textColor ? props.textColor : 'white'};
  padding: 1rem;
  width: 25rem;
  overflow:auto;
  display:flex;
  flex-direction:column-reverse;
  border-radius: 0 0 0 8px;
  z-index: 200;

  li:hover {
    opacity: 0.6;
  }

  a {
    color: ${props => props.textColor ? props.textColor : 'white'};
    text-decoration: none
  }
  @media (max-width: 650px) {
    width: 100vw;
    bottom: 0;
    top: inherit;
    padding: 1rem;
    border-radius: 8px 8px 0 0;
    height: calc(var(--vh, 1vh) * 27);
  }
`;

const StyledDivFull = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin:0;
  background-color: ${props => props.bgColor ? props.bgColor : 'black'};
  color: ${props => props.textColor ? props.textColor : 'white'};
  padding: 1em;
  height: 100vh;
  display:flex;
  @media (max-width: 650px) {

    padding: 0.5rem;

  }
  a {
    color: ${props => props.textColor ? props.textColor : 'white'};
    text-decoration: none
  }
`;
