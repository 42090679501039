import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url';

const client = sanityClient({
  projectId: '4iayedul', // you can find this in sanity.json
  dataset: 'production', // or the name you chose in step 1
  useCdn: true // `false` if you want to ensure fresh data
});

export default client;

const builder = imageUrlBuilder(client);

export const urlForTrack = (track) => {
  return `https://cdn.sanity.io/files/4iayedul/production/${track.audiofile.asset._ref.replace('file-', '').replace('-mp3', '')}.mp3`
};

export const urlFor = (source) => {
  return builder.image(source.asset)
};

export const urlForSVG = (release) => {
  return `https://cdn.sanity.io/files/4iayedul/production/${release.symbol.asset._ref.replace('file-', '').replace('-svg', '')}.svg`
};
