import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import shortid from 'short-id';
import {StorageMediaContext} from '../../storageMedia/storageMediaContext';

const About = props => {
  const { dispatch } = useContext(StorageMediaContext);
  const aboutText = 'Established in 2020, STORAGE MEDIA is a publishing platform that seeks to explore the question; what constitutes a release? ';
  useEffect(() => {
    dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'emptyLine', 'payload': { id: shortid.generate() } } });
    dispatch({ 'type': 'NEW_TERMINAL_LINE', 'payload': { type: 'text', 'payload': { id: shortid.generate(), text: aboutText } } });
  }, [dispatch]);
  return (
    <div>
      {/*<p>About this project</p>*/}

      {/*<p>*/}
      {/*  <Link to='/'>&lt; Go to index</Link>*/}
      {/*</p>*/}
    </div>
  );
};

export default About;
