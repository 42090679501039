import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import {useAudioPlayer} from '../../storageMedia/storageMediaHooks';

let trackName = "trackName";
let url = "https://file-examples.com/wp-content/uploads/2017/11/file_example_MP3_1MG.mp3";

const AudioPlayer = props => {
  const { playing, stop, play, pause, playURL, url } = useAudioPlayer();
  console.log({ playing, stop, play, pause, playURL, url });
  const ref = React.createRef();

  return (
    <div className="audio-player"style={{ display: 'none' }} >
      <ReactPlayer
        ref={ref}
        url={url}
        playing={playing}
        className="videoPlayer"
        volume={1}
      />
    </div>
  );
};

export default AudioPlayer;
