import React, { useReducer } from 'react'
import { format } from 'date-fns'
import shortid from 'short-id';
import { StorageMediaContext } from './storageMediaContext'

const stateReducer = (state , action) => {
  switch (action.type) {
    case 'NEW_TERMINAL_LINE':
      console.log('NEW_TERMINAL_LINE payload', action.payload);
      switch (action.payload.type) {
        case 'emptyLine':
          return { ...state, 'terminalLines': [...state.terminalLines, { 'type': 'emptyLine', ...action.payload.payload}]};
        case 'text':
          return { ...state, 'terminalLines': [...state.terminalLines, { 'type': 'text', ...action.payload.payload}]};
        case 'internalLink':
          return { ...state, 'terminalLines': [...state.terminalLines, { 'type': 'internalLink', ...action.payload.payload}]};
        default:
          console.log('NEW_TERMINAL_LINE no payload.type');
          return state
      }
    case 'SET_TERMINAL_COLORS':
      return { ...state, terminalTextColor: action.payload.terminalTextColor, terminalBgColor: action.payload.terminalBgColor };
    case 'SET_HOVERED_RELEASE':
      return { ...state, hoveredRelease: action.payload.hoveredRelease };
    case 'SET_RELEASE':
      return { ...state, release: action.payload.release };
    case 'AUDIO_PLAYER_SET_URL':
      return { ...state, audioPlayer: { ...state.audioPlayer, url: action.payload.url } };
    case 'AUDIO_PLAYER_PLAY':
      return { ...state, audioPlayer: { ...state.audioPlayer, playing: true } };
    case 'AUDIO_PLAYER_STOP':
      return { ...state, audioPlayer: { ...state.audioPlayer, playing: false, url: null } };
    case 'AUDIO_PLAYER_PAUSE':
      return { ...state, audioPlayer: { ...state.audioPlayer, playing: false } };
    case 'AUDIO_PLAYER_PLAYPAUSE':
      return { ...state, audioPlayer: { ...state.audioPlayer, playing: !state.audioPlayer.playing } };
    case 'CAROUSEL_SET_REF':
      return { ...state, carousel: { ...state.carousel, ref: action.payload.carousel.ref } };
    case 'CAROUSEL_SET_SLIDE':
      return { ...state, carousel: { ...state.carousel, currentSlide: action.payload.carousel.currentSlide } };
    default:
      console.warn('SM: Unhandled message');
      return state
  }
};

const initialState = {
  hoveredRelease: null,
  release: null,
  audioPlayer: {
    playing: false,
    url: null
  },
  carousel: {
    ref: null,
    currentSlide: 0
  },
  terminalLines: [
    {
      'id': 0,
      'text': 'storagemedia.net v0.001',
      'type': 'text',
    },
    {
      'id': 1,
      'type': 'emptyLine'
    },
    {
      'id': 2,
      'type': 'text',
      'text': `last login ${format(
        new Date(),
        "hh:mmaaaaa'm'"
      )}`
    },
    {
      'id': 3,
      'type': 'emptyLine'
    },
    {
      'id': 4,
      'text': 'releases',
      'type': 'internalLink',
      'src': '/releases'
    },
    {
      'id': 5,
      'text': 'about',
      'type': 'internalLink',
      'src': '/about'
    }
  ],
};

const StorageMediaProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  // TODO will rerender children every render because this is a new object each render
  const context = {
    state,
    dispatch
  };

  return (
    <StorageMediaContext.Provider value={context}>{children}</StorageMediaContext.Provider>
  )
};

export default StorageMediaProvider
