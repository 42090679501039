import React from 'react';
import styled from 'styled-components';

import defaultBarcode from '../../assets/svg/default_barcode_white.png'

const urlForSVG = (release) => {
  return `https://cdn.sanity.io/files/4iayedul/production/${release.symbol.asset._ref.replace('file-', '').replace('-svg', '')}.svg`
};

const Barcode = ({ release }) => {
  return (
    <StyledDiv>
      <img alt="symbol of release" src={(release && release.symbol && urlForSVG(release)) || defaultBarcode} />
    </StyledDiv>
  );
};

export default Barcode;

const StyledDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  color: white;
  width: 16rem;
  padding: 1.5rem;
  @media (max-width: 650px) {
    bottom: inherit;
    left: inherit;
    width: 8rem;
    right: 0;
    top: 0;
    padding: 0.5rem;
  }
`;
