import React from 'react';
import {Link} from 'react-router-dom';

const LiveBroadcast = props => {
  return (
    <div>
      <p>Live Broadcast</p>

      <p>
        <Link to='/'>&lt; Go to index</Link>
      </p>
    </div>
  );
};

export default LiveBroadcast;
