import React, { useState, useLayoutEffect } from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from '../Layout';
import Release from '../Release';
import ReleaseList from '../ReleaseList';
import About from '../About';
import Homepage from '../Homepage';
import LiveBroadcast from '../LiveBroadcast';
import StorageMediaProvider from '../../storageMedia/storageMediaProvider';

import './App.css';

// This needs to be wrapped in a listener


const App = () => {
  // TODO: move release to global state
  const [release, setRelease] = useState({});
  useLayoutEffect(() => {
    function updateVH() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', updateVH);
    updateVH();
    return () => window.removeEventListener('resize', updateVH);
  }, []);

  return (
    <StorageMediaProvider>
      <BrowserRouter>
        <Layout release={release} setRelease={setRelease}>
          <Switch>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/releases/">
              <ReleaseList />
            </Route>
            <Route exact path="/live-broadcast">
              <LiveBroadcast />
            </Route>
            <Route path="/releases/:id">
              <Release release={release} setRelease={setRelease} />
            </Route>
            <Route exact path="/">
              <Homepage />
            </Route>
          </Switch>
        </Layout>
      </BrowserRouter>
    </StorageMediaProvider>
  )
};

export default App;
