import React, { useContext } from 'react';
import Barcode from '../Barcode'
import Terminal from '../Terminal'
import { useLocation } from 'react-router-dom';
import {useAudioPlayer, useStorageMediaState} from '../../storageMedia/storageMediaHooks';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const Layout = ({ children }) => {
  const state = useStorageMediaState();
  const { release, hoveredRelease } = state;
  console.log('Layout state', state);
  console.log('Layout release', release);
  const pathName = useLocation().pathname;
  console.log('Layout pathName', pathName);

  const { playURL } = useAudioPlayer();
  let url = "https://file-examples.com/wp-content/uploads/2017/11/file_example_MP3_1MG.mp3";
  const handleClick = () => {
    console.log('about to set url');
    playURL(url);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column'}}>
      <div style={{ maxHeight: '80vh', height: 'calc(100vh - 11rem)', width: '100%'}}>{children}</div>
      <Terminal textColor={state.terminalTextColor} bgColor={state.terminalBgColor} pathName={pathName} />
      {
        // TODO: move release to global state and add a new property in global state that is hoveredRelease...
        // TODO: ...so that we can show the barcode for either the hoveredRelease or the selected release
      }
      <AudioPlayer />
      <Barcode release={hoveredRelease || release} />
    </div>
  );
};

export default Layout;
