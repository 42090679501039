import {useContext, useEffect} from 'react'
import { StorageMediaContext } from './storageMediaContext'
import client from '../lib/sanity';
import shortid from 'short-id';
import {loadReleaseList} from './storageMediaHelpers';

export const useStorageMediaState = () => {
  const { state } = useContext(StorageMediaContext);
  return state
};

export const useAudioPlayer = () => {
  const { state, dispatch } = useContext(StorageMediaContext);
  const { playing, url } = state.audioPlayer;
  const playURL = (url) => {
    dispatch({ type: 'AUDIO_PLAYER_SET_URL', payload: { url }});
    dispatch({ type: 'AUDIO_PLAYER_PLAY', payload: {}});
  };
  const play = () => dispatch({ type: 'AUDIO_PLAYER_PLAY', payload: {}});
  const stop = () => dispatch({ type: 'AUDIO_PLAYER_STOP', payload: {}});
  const pause = () => dispatch({ type: 'AUDIO_PLAYER_PAUSE', payload: {}});
  const playPause = () => dispatch({ type: 'AUDIO_PLAYER_PLAYPAUSE', payload: {}});
  return { playing, url, stop, play, pause, playPause, playURL }
};

export const useLoadReleaseList = () => {
  const { dispatch } = useContext(StorageMediaContext);
  let fetchData;
  useEffect(() => {
    let isCancelled = false;
    fetchData = async () => {
      if (!isCancelled) {
        loadReleaseList(dispatch)
      }
    };
    fetchData();

    return () => {
      isCancelled = true;
    }
  }, []);
};
