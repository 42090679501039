import React, { useEffect, useState, useContext } from 'react';
import client from '../../lib/sanity';
import { Link } from 'react-router-dom';
import { StorageMediaContext } from '../../storageMedia/storageMediaContext'
import { useLoadReleaseList } from '../../storageMedia/storageMediaHooks';
import shortid from 'short-id';

const ReleaseList = () => {
  const { dispatch } = useContext(StorageMediaContext);
  const [releases, setReleases] = useState([]);
  // useLoadReleaseList();

  return (
    <div>
      {/*<h1>Release List</h1>*/}
      {/*{releases.length > 0 && (*/}
      {/*  <ul>*/}
      {/*    {releases.map((release) => (*/}
      {/*    <li key={release.number}>*/}
      {/*      <Link to={`releases/${release.slug.current}`} onMouseEnter={() => handleMouseEnter(release)} onMouseLeave={handleMouseLeave}>Release {release.number}</Link>*/}
      {/*    </li>*/}
      {/*    ))}*/}
      {/*  </ul>*/}
      {/*)}*/}
      {/*<p>*/}
      {/*  <Link to='/'>&lt; Go to index</Link>*/}
      {/*</p>*/}
    </div>
  );
};

export default ReleaseList;
